import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import FullMonth from '../components/FullMonth'
import NavButton from '../components/NavButton'
import { getHappyValues } from '../utils/queries'

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const DashboardMonth = () => {

  const [happyValues, setHappyValues] = useState()
  useEffect(()=>{
    getHappyValues(sessionStorage.getItem('token')).then(data => setHappyValues(data))
  }, [])

  const location = useLocation()

  const [year, setYear] = useState(location.state.year)
  const [month, setMonth] = useState(location.state.month)

  const shiftMonth = (isIncrement) => {

    if (isIncrement) {
      if (month === 11) {
        setYear(year + 1)
        setMonth(0)
      } else {
        setMonth(month + 1)
      }
    } else {
      if (month === 0) {
        setYear(year - 1)
        setMonth(11)
      } else {
        setMonth(month - 1)
      }
    }

  }

  return (
    <div className='background centeredColumn pcCenter'>

      <h1 className='monthHeader'>{months[month]}, {year}</h1>

      <Link to='/' state={{year}}>
        <button style={{marginBottom: '3vh'}} className='navArrow noMobileDisplay'></button>
      </Link>
      <div className='goalButton'>
        <NavButton text={months[month] + ' ' + year + ' Goals'} path='/goalsmonth' state={{year, month}} className='lightGreenButton'/>
      </div>
      <div className='spacedRow' style={{gap: '5vw'}}>
        <button className='navArrow noMobileDisplay' style={{transform: 'rotate(-90deg)'}} onClick={ ()=>{shiftMonth(false)} }></button>
        <FullMonth month={month} year={year} happyValues={happyValues}/>
        <button className='navArrow noMobileDisplay' style={{transform: 'rotate(90deg)'}} onClick={ ()=>{shiftMonth(true)} }></button>
      </div>

      <div className='aboutButton'>
        <NavButton text='About' path='/about'/>
      </div>
      <div className='accountButton'>
        <NavButton text='Account' path='/account'/>
      </div>
      <div className='goalButton onlyMobileDisplay'>
        <NavButton text='Back' path='/' state={{year}} className='darkGreenButton'/>
      </div>

    </div>
  )
}

export default DashboardMonth