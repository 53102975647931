import React from 'react'
import CircleCell from './CircleCell'
import { Link } from 'react-router-dom'
import { getColorTone } from '../utils/colors'

const MiniMonth = ({month, year, happyValues}) => {

    const days = ["S", "M", "T", "W", "T", "F", "S"]
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const firstDayOfWeek = new Date(year, month, 1).getDay()
    const daysInMonth = new Date( year, parseInt(month)+1, 0).getDate()
    
    return (
        
            <div className='shadowBox monthBox'>
                <Link to='/month' state={{month, year}}>
                    <h3 style={{fontSize: '1.8rem', textAlign: 'center', borderRadius: '2vh', backgroundColor: '#BBF3FF'}}>{months[month]}</h3>
                </Link>
                <div className='calendarGrid'>
                    { days.map( (day, i) => {   return <CircleCell key={i} text={day} color='#BBF3FF' />}) }
                    { [...Array(firstDayOfWeek).keys()].map((e, i) =>{   return <div key={i}></div>   }) }
                    { [...Array(daysInMonth).keys()].map((e, i) =>{   
                        return <Link key={i} style={{width: '100%', height: '100%', borderRadius: '50%'}} to='/goalsday' 
                                     state={{year, month: month, day: e + 1}}>
                                    <CircleCell text={e + 1} color={getColorTone(happyValues, year + '-' + (month+1) + '-' + (e + 1))} />
                               </Link>   }) }
                </div>
            </div>
        
    )
}

export default MiniMonth