import React, {useState, useEffect, useMemo} from 'react'
import { useLocation } from 'react-router'
import NavButton from '../components/NavButton'
import { getGoal, updateGoal } from '../utils/queries'
import debounce from 'lodash.debounce';


const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const GoalsMonth = () => {

  const location = useLocation()

  const [year, setYear] = useState(location.state.year)
  const [month, setMonth] = useState(location.state.month)
  const [yearGoal, setYearGoal] = useState('')
  const [monthGoal, setMonthGoal] = useState('')

  const shiftMonth = (isIncrement) => {
    if (isIncrement) {
      if (month === 11) {
        setYear(year + 1)
        setMonth(0)
      } else {
        setMonth(month + 1)
      }
    } else {
      if (month === 0) {
        setYear(year - 1)
        setMonth(11)
      } else {
        setMonth(month - 1)
      }
    }
  }

  useEffect(()=>{
    getGoal(year).then(data=>{setYearGoal(data ? data: '')})
  }, [year])

  useEffect(()=>{
    getGoal(year + '-' + (month+1)).then(data=>{setMonthGoal(data ? data: '')})
  }, [year, month])

  function handleYearEdit(g) {
    debouncedHandleYearEdit(g)
    setYearGoal(g)
  }
  function handleMonthEdit(g) {
    debouncedHandleMonthEdit(g)
    setMonthGoal(g)
  }

  const debouncedHandleYearEdit = useMemo(
    () => debounce((g)=>{
        updateGoal({date: year, text: g, login_token: sessionStorage.getItem('token')})
      }, 1000), [year]
  )

  const debouncedHandleMonthEdit = useMemo(
    () => debounce((g)=>{
        updateGoal({date: year + '-' + (month+1), text: g, login_token: sessionStorage.getItem('token')})
      }, 1000), [year, month]
  )

  return (
    <div className='background centeredColumn'>

      <div className='spacedRow mobileShrink' style={{minWidth: '60vw'}}>
        <button className='navArrow' style={{transform: 'rotate(-90deg)'}} onClick={ ()=>{shiftMonth(false)} }></button>
        <h1 style={{margin: '4vh 0vh'}}>{months[month]}, {year}</h1>
        <button className='navArrow' style={{transform: 'rotate(90deg)'}} onClick={ ()=>{shiftMonth(true)} }></button>
      </div>

      <div className='spacedRow mobileColumn' style={{gap: '5vw'}}>
        <div className='centeredColumn'>
          <h3>{months[month]} Goals</h3>
          <textarea className='shadowBox inputBox goalBox' style={{backgroundColor: '#CCFAD9'}}
            onChange={e=>{handleMonthEdit(e.target.value)}} value={monthGoal}>
          </textarea>
        </div>
        <div className='centeredColumn'>
          <h3>{year} Goals</h3>
          <textarea className='shadowBox inputBox goalBox' style={{backgroundColor: '#75D490'}}
            onChange={e=>{handleYearEdit(e.target.value)}} value={yearGoal}>
          </textarea>
        </div>  
      </div>
      

      <div className='goalButton'>
        <NavButton text='Back' path='/month' state={{month, year}} className='darkGreenButton'/>
      </div>

    </div>
  )
}

export default GoalsMonth