import React from 'react'

const CircleCell = ({text, color}) => {
    return (
        <div className='circleCell' style={{backgroundColor: color}}>
            <p>{text}</p>
        </div>
    )
}

export default CircleCell