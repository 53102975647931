import React, {useState, useEffect, useMemo} from 'react'
import { useLocation } from 'react-router'
import NavButton from '../components/NavButton'
import { Link } from 'react-router-dom'
import { getGoal, getHappyValues, updateGoal, updateHappyValue } from '../utils/queries'
import debounce from 'lodash.debounce';

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

const GoalsDay = () => {

  const location = useLocation()

  const [year, setYear] = useState(location.state.year)
  const [month, setMonth] = useState(location.state.month)
  const [day, setDay] = useState(location.state.day)
  const [happy, setHappy] = useState(0)
  const [dayGoal, setDayGoal] = useState('')
  const [monthGoal, setMonthGoal] = useState('')

  let date = new Date(year, month, day)
  let shortDate = year + '-' + (month+1) + '-' + day

  const shiftDay = (isIncrement) => {
    if (isIncrement) {
      date.setDate(date.getDate() + 1)
    } else {
      date.setDate(date.getDate() - 1)
    }
    setDay(date.getDate())
    setMonth(date.getMonth())
    setYear(date.getFullYear())
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(()=>{
    let shortDate = year + '-' + (month+1) + '-' + day
    getGoal(shortDate).then(data=>{setDayGoal(data ? data: '')})
    getHappyValues(sessionStorage.getItem('token')).then(data => setHappy(data[shortDate] ? data[shortDate] : 0))
  }, [year, month, day])

  useEffect(()=>{
    getGoal(year + '-' + (month+1)).then(data=>{setMonthGoal(data ? data: '')})
  }, [year, month])

  function handleDayEdit(g) {
    debouncedHandleDayEdit(g)
    setDayGoal(g)
  }
  function handleMonthEdit(g) {
    debouncedHandleMonthEdit(g)
    setMonthGoal(g)
  }
  function handleHappyValueEdit(v) {
    debouncedHandleHappyValueEdit(v)
    setHappy(v)
  }

  const debouncedHandleDayEdit = useMemo(
    () => debounce((g)=>{
        updateGoal({date: shortDate, text: g, login_token: sessionStorage.getItem('token')})
      }, 1000), [shortDate]
  )

  const debouncedHandleMonthEdit = useMemo(
    () => debounce((g)=>{
        updateGoal({date: year + '-' + (month+1), text: g, login_token: sessionStorage.getItem('token')})
      }, 1000), [year, month]
  )

  const debouncedHandleHappyValueEdit = useMemo(
    () => debounce((v)=>{
        updateHappyValue({date: shortDate, value: v, login_token: sessionStorage.getItem('token')})
      }, 1000), [shortDate]
  )

  return (
    <div className='background centeredColumn'>

      <Link to='/month' state={{month: month, year}}>
        <button style={{marginBottom: '3vh'}} className='navArrow'></button>
      </Link>

      <h1>{months[month]} {day}, {year} ({days[date.getDay()]})</h1>

      <div className='spacedRow mobileColumn' style={{gap: '3vw'}}>
        <button className='navArrow noMobileDisplay' style={{transform: 'rotate(-90deg)'}} 
                onClick={()=>{shiftDay(false)}}></button>

        <div className='centeredColumn'>
          <h3>{days[date.getDay()]} Goals</h3>
          <textarea className='shadowBox inputBox goalBox' style={{minHeight: '50vh'}}
            onChange={e=>{handleDayEdit(e.target.value)}} value={dayGoal}>
          </textarea>

          <div className='shadowBox happyBar' style={{backgroundColor: 'var(--tone' + happy + ')'}}>
            <div className='spacedRow'>
              <button className='navArrow noMobileMargin' style={{transform: 'rotate(-90deg)'}} 
                      onClick={()=>{handleHappyValueEdit(Math.max(happy - 1, 0))}}></button>
              <h2>Mood: {happy}/10</h2>
              <button className='navArrow noMobileMargin' style={{transform: 'rotate(90deg)'}} 
                      onClick={()=>{handleHappyValueEdit(Math.min(happy + 1, 10))}}></button>
            </div>
          </div>

        </div>

        <div className='centeredColumn'>
          <h3>{months[month]} Goals</h3>
          <textarea className='shadowBox inputBox goalBox' style={{backgroundColor: '#CCFAD9'}}
            onChange={e=>{handleMonthEdit(e.target.value)}} value={monthGoal}>
          </textarea>
        </div>

        <button className='navArrow noMobileDisplay' style={{transform: 'rotate(90deg)'}} 
                onClick={()=>{shiftDay(true)}}></button>
        
        <div className='goalButton'>
          <NavButton text='Home' path='/' state={{year}} className='darkGreenButton'/>
        </div>

      </div>

    </div>
  )
}

export default GoalsDay