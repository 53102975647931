import React, {useState, useEffect} from 'react'
import NavButton from '../components/NavButton'
import { getAccountInfo, updateEmail } from '../utils/queries'

const Account = () => {

  const [stayLoggedIn, setStayLoggedIn] = useState(localStorage.getItem('token') ? true : false)
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState()
  const [result, setResult] = useState({msg: 'default', color: 'red'});

  useEffect(()=>{
    getAccountInfo(sessionStorage.getItem('token')).then(data => {
      setUsername(data.username)
      setEmail(data.email)
    })
  }, [])

  function handleCheckbox( e ) {
    e ? localStorage.setItem('token', sessionStorage.getItem('token')) :
        localStorage.removeItem('token')
  }

  const handleSubmit = async e => {
        e.preventDefault();
        console.log("Ran UpdateEmail with: " + email)
        const result = await updateEmail({login_token: sessionStorage.getItem('token'), email: email});
        if (result.error) {
          setResult({msg: result.error, color: 'red'})
        } else {
          setResult({msg: result.success, color: 'green'})
        }
    }

  return (
    <div className='background centeredColumn' style={{justifyContent: 'center'}}>

      <h2 style={{marginBottom: '1rem', textAlign: 'center'}}>Account - {username}</h2>

      <div className='shadowBox aboutBox'>

        <form  onSubmit={handleSubmit} className='centeredColumn' style={{alignItems: 'flex-start'}}>
          <h3>Edit Email:</h3>
          <input className='loginInput' style={{fontSize: '1.1rem', width: '90%', padding: '0.8rem 5%'}} 
                 type="text" value={email} onChange={e => setEmail(e.target.value)}/>
          <button type='submit' className='loginButton'>Save</button>
          <p style={{color: result.color, visibility: result.msg === 'default' ? 'hidden' : 'visible'}}>{result.msg}</p>
        </form>

        <div className='spacedRow' style={{justifyContent: 'flex-start', gap: '1rem'}}>
          <h3>Stay logged in -</h3>
          <input className='checkbox' type='checkbox' checked={stayLoggedIn} onChange={e=>{
                handleCheckbox( e.target.checked ); setStayLoggedIn(e.target.checked)}} />
        </div>

      </div>

      <div style={{position: 'absolute', right: '2vw', bottom: '4vh'}}>
        <NavButton text='Back' path='/'/>
      </div>

    </div>
  )
}

export default Account