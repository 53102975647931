import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import NavButton from '../components/NavButton'
import {createAccount} from '../utils/queries'

const Signup = ({setToken}) => {

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [password2, setPassword2] = useState();
    const [email, setEmail] = useState();
    const [error, setError] = useState('default');

    const handleSubmit = async e => {
        e.preventDefault();
        const token = await createAccount( {username, password, password2, email} );
        token.hasOwnProperty('error') ? setError(token.error) : setToken(token.token)
    }

    return (
      <div className='background centeredColumn' style={{justifyContent: 'center'}}>
        <div className='shadowBox'>
          <h2 style={{textAlign:'center'}}>Sign Up</h2>

          <form className='inputForm' onSubmit={handleSubmit}>
              <h3>Username</h3>
              <input className='loginInput' type="text" onChange={e => {setUserName(e.target.value)}}/>
              <h3>Password</h3>
              <input className='loginInput' type="password" onChange={e => setPassword(e.target.value)}/>
              <h3>Confirm Password</h3>
              <input className='loginInput' type="password" onChange={e => setPassword2(e.target.value)}/>
              <h3>Recovery Email</h3>
              <input className='loginInput' type="text" onChange={e => setEmail(e.target.value)}/>
              <p style={{color: 'red', visibility: error === 'default' ? 'hidden' : 'visible'}}>{error}</p>
              <div className='spacedRow'>
                <button className='loginButton' type="submit">Submit</button> 
                <Link to='/login'>
                  <button className='loginButton'>Login</button>
                </Link>       
              </div>
          </form>

        </div>

        <div className='aboutButton'>
          <NavButton text='About' path='/about'/>
        </div>

      </div>
    )
}

export default Signup