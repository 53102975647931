import React from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import useToken from './utils/useToken'

import About from './pages/About'
import Account from './pages/Account'
import DashboardMonth from './pages/DashboardMonth'
import DashboardYear from './pages/DashboardYear'
import GoalsDay from './pages/GoalsDay'
import GoalsMonth from './pages/GoalsMonth'
import GoalsYear from './pages/GoalsYear'
import Login from './pages/Login'
import Signup from './pages/Signup'
//import Test from './pages/Test'

function App() {
  
  const { token, setToken } = useToken();

  if(!token) {
    return ( 
      <Router>
        <Routes>
            <Route path='/' element={<Login setToken={setToken}/>} />
            <Route path='/signup' element={<Signup setToken={setToken}/>} />
            <Route path='/about' element={<About />} />
            <Route path='*' element={<Login setToken={setToken}/>} />
          </Routes>
      </Router> 
    )
  }

  return(
    <Router>
      <Routes>

        <Route path='/' element={<DashboardYear />} />
        <Route path='/month' element={<DashboardMonth />} />
        <Route path='/goalsday' element={<GoalsDay />} />
        <Route path='/goalsmonth' element={<GoalsMonth />} />
        <Route path='/goalsyear' element={<GoalsYear />} />
        <Route path='/about' element={<About />} />
        <Route path='/account' element={<Account />} />        
        {/*<Route path='/test' element={<Test />} /> */}       

        <Route path='*' element={<DashboardYear />} />

      </Routes>
    </Router>    
  );
}

export default App;
