import { useState } from 'react';

export default function useToken() {

    const getToken = () => {
        let tokenString = undefined
        if (localStorage.getItem('token')){
            tokenString = localStorage.getItem('token');
            sessionStorage.setItem('token', tokenString);
        } else {
            tokenString = sessionStorage.getItem('token');
        }
        return tokenString;
    }

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken, stayLoggedIn) => {
        //If they stay logged in, set local storage. If not, clear local storage.
        stayLoggedIn ? localStorage.setItem('token', userToken) : localStorage.removeItem('token')
        sessionStorage.setItem('token', userToken);
        setToken(userToken);
    }

    return {
        token,
        setToken: saveToken
    }

}