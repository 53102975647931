const URL = `http://localhost:8080`; // `https://benlog-backend.herokuapp.com` //

export async function loginUser(credentials) {
  return fetch(URL + "/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export async function createAccount(credentials) {
  return fetch(URL + "/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export async function getGoal(date) {
  return fetch(URL + "/goal/" + date + "/" + sessionStorage.getItem("token"), {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      return data.goal;
    });
}

export async function updateGoal(goalInfo) {
  return fetch(URL + "/goal", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(goalInfo),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data.success);
    });
}

export async function getHappyValues(loginToken) {
  return fetch(URL + "/happy/" + sessionStorage.getItem("token"), {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export async function updateHappyValue(hvInfo) {
  return fetch(URL + "/happy", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(hvInfo),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data.success);
    });
}

export async function getAccountInfo(date) {
  return fetch(URL + "/account/" + sessionStorage.getItem("token"), {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export async function updateEmail(updateInfo) {
  return fetch(URL + "/email", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(updateInfo),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}
