import React from 'react'
import {Link} from 'react-router-dom'

const NavButton = ({text, path, state, className}) => {
  return (
    <Link to={path} state={state}>
        <button className={'navButton ' + className}>{text}</button>
    </Link>
  )
}

export default NavButton