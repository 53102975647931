import React, {useState, useEffect, useMemo} from 'react'
import { useLocation } from 'react-router'
import NavButton from '../components/NavButton'
import { getGoal, updateGoal } from '../utils/queries'
import debounce from 'lodash.debounce';

const GoalsYear = () => {

  const location = useLocation()
  const [year, setYear] = useState(location.state.year)
  const [goal, setGoal] = useState('')

  useEffect(()=>{
    getGoal(year).then(data=>{setGoal(data ? data: '')})
  }, [year])

  const handleEdit = (g) => {
    debouncedHandleEdit(g)
    setGoal(g)
  }

  const debouncedHandleEdit = useMemo(
    () => debounce((g)=>{
        updateGoal({date: year, text: g, login_token: sessionStorage.getItem('token')})
      }, 1000), [year]
  ) 

  return (
    <div className='background centeredColumn'>

      <div className='spacedRow' style={{minWidth: '50vw'}}>
        <button className='navArrow' style={{transform: 'rotate(-90deg)'}} onClick={()=>{setYear((Number(year) - 1))}}></button>
        <h1 style={{margin: '4vh'}}>{year} Goals</h1>
        <button className='navArrow' style={{transform: 'rotate(90deg)'}} onClick={()=>{setYear((Number(year) + 1))}}></button>
      </div>

      
      <textarea className='shadowBox inputBox goalBox' style={{backgroundColor: '#75D490'}}
          onChange={e=>{handleEdit(e.target.value)}} value={goal}>
      </textarea>

      <div className='goalButton'>
        <NavButton text='Back' path='/' className='darkGreenButton'/>
      </div>

    </div>
  )
}

export default GoalsYear