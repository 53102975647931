import React from 'react'
import NavButton from '../components/NavButton'
import { aboutText } from '../utils/aboutText'

const About = () => {

  return (
    <div className='background centeredColumn' style={{justifyContent: 'center'}}>
      <div className='shadowBox aboutBox'>
        <h2 style={{marginBottom: '1rem', textAlign: 'center'}}>About BenLog</h2>
        <p style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{__html: aboutText}}></p>
      </div>

      <div style={{position: 'absolute', right: '2vw', bottom: '4vh'}}>
        <NavButton text='Back' path='/'/>
      </div>

    </div>
  )
}

export default About