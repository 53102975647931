import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router'
import MiniMonth from '../components/MiniMonth'
import NavButton from '../components/NavButton'
import { getHappyValues } from '../utils/queries'

const DashboardYear = () => {
  
  const [happyValues, setHappyValues] = useState()
  const location = useLocation()
  const [year, setYear] = useState(location.state ? location.state.year : new Date().getFullYear())

  useEffect(()=>{
    getHappyValues(sessionStorage.getItem('token')).then(data => setHappyValues(data))
  }, [])

  return (
    <div className='background centeredColumn'>

      <div className='spacedRow yearHeader' style={{minWidth: '50vw'}}>
        <button className='navArrow' style={{transform: 'rotate(-90deg)'}} onClick={()=>{setYear((Number(year) - 1))}}></button>
        <h1>{year}</h1>
        <button className='navArrow' style={{transform: 'rotate(90deg)'}} onClick={()=>{setYear((Number(year) + 1))}}></button>
      </div>

      <div className='goalButton'>
        <NavButton text={year + ' Goals'} path='/goalsyear' state={{year}} className='darkGreenButton'/>
      </div>

      <div className='yearCalendarGrid'>
            { [...Array(12).keys()].map((e, i) =>{   return <MiniMonth key={i} month={e} year={year} happyValues={happyValues}/>   }) }
      </div>

      <div className='aboutButton'>
        <NavButton text='About' path='/about'/>
      </div>
      <div className='accountButton'>
        <NavButton text='Account' path='/account'/>
      </div>

    </div>
  )
}

export default DashboardYear