import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import NavButton from '../components/NavButton'
import { loginUser } from '../utils/queries'

const Login = ({setToken}) => {

  const [username, setUserName] = useState()
  const [password, setPassword] = useState()
  const [error, setError] = useState('default')
  const [stayLoggedIn, setStayLoggedIn] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser( {username, password} );
    token.hasOwnProperty('error') ? setError(token.error) : setToken(token.token, stayLoggedIn);
  }

  return (
    <div className='background centeredColumn' style={{justifyContent: 'center'}}>
      <div className='shadowBox'>
        <h2 style={{textAlign:'center'}}>Login</h2>

        <form className='inputForm' onSubmit={handleSubmit}>
            <h3>Username</h3>
            <input className='loginInput' type="text" onChange={e => {setUserName(e.target.value)}}/>
            <h3>Password</h3>
            <input className='loginInput' type="password" onChange={e => setPassword(e.target.value)}/>
            <div className='spacedRow' style={{justifyContent:'flex-start', gap:'1vw'}}>
              <input className='checkbox' type="checkbox" onChange={e => setStayLoggedIn(!stayLoggedIn)}></input>
              <p>Stay logged in</p>
            </div>
            <p style={{color: 'red', visibility: error === 'default' ? 'hidden' : 'visible'}}>{error}</p>
            <div className='spacedRow'>
              <button className='loginButton' type="submit">Submit</button>
              <Link to='/signup'>
                <button className='loginButton'>Sign Up</button>
              </Link>
            </div>
        </form>

      </div>

      <div className='aboutButton'>
        <NavButton text='About' path='/about'/>
      </div>

    </div>
  )
}

export default Login